export class D2CPreReservationDiscountDto {
    constructor(initializer) {
        this.id = initializer.id;
        this.discountCodeId = initializer.discountCodeId;
        this.userDiscountPolicyId = initializer.userDiscountPolicyId;
        this.userDiscountPolicy = initializer.userDiscountPolicy;
        this.discountType = initializer.discountType;
        this.discountValue = initializer.discountValue;
        this.discountAmount = initializer.discountAmount;
        this.createdAt = initializer.createdAt;
        this.updatedAt = initializer.updatedAt;
        this.deletedAt = initializer.deletedAt;
    }
}
