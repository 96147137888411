var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsDateString, IsEnum, IsNumber, IsOptional, } from 'class-validator';
import { TransformBoolean } from '../../../decorators';
import { InsuranceName } from '../../../enums';
export class GetVehicleModelReservableForD2CQueryDto {
}
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], GetVehicleModelReservableForD2CQueryDto.prototype, "companyId", void 0);
__decorate([
    IsEnum(InsuranceName, { each: true }),
    __metadata("design:type", String)
], GetVehicleModelReservableForD2CQueryDto.prototype, "insuranceName", void 0);
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], GetVehicleModelReservableForD2CQueryDto.prototype, "pickUpAt", void 0);
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], GetVehicleModelReservableForD2CQueryDto.prototype, "dropOffAt", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    TransformBoolean(),
    __metadata("design:type", Boolean)
], GetVehicleModelReservableForD2CQueryDto.prototype, "isFullCoverage", void 0);
