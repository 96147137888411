var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseStringIdDataDto } from '../../../classes/common';
import { VehicleModelWithImagesDto } from '../../../classes/vehicle-model';
import { VendorWithoutRelationsDto } from '../../../classes/vendor/response/vendor-without-relations.dto';
import { ReservationExtraItemDto } from './reservation-extra-item.dto';
import { ReservationInsuranceDto } from './reservation-insurance.dto';
export class ReservationInsuranceInfoWithPreReservationDto extends BaseStringIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.pickUpAt = initializer.pickUpAt;
        this.dropOffAt = initializer.dropOffAt;
        this.vehicleModel = initializer.vehicleModel;
        this.vehicleModelId = initializer.vehicleModelId;
        this.finalPrice = initializer.finalPrice;
        this.state = initializer.state;
        this.vendor = initializer.vendor;
        this.vendorId = initializer.vendorId;
        this.customerName1 = initializer.customerName1;
        this.customerPhoneNumber1 = initializer.customerPhoneNumber1;
        this.insuranceOption = initializer.insuranceOption;
        this.extraItems = initializer.extraItems;
        this.insurances = initializer.insurances;
        this.preReservation = initializer.preReservation;
        this.insuranceInfo = initializer.insuranceInfo;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationInsuranceInfoWithPreReservationDto.prototype, "pickUpAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], ReservationInsuranceInfoWithPreReservationDto.prototype, "dropOffAt", void 0);
__decorate([
    Type(() => VendorWithoutRelationsDto),
    __metadata("design:type", VendorWithoutRelationsDto)
], ReservationInsuranceInfoWithPreReservationDto.prototype, "vendor", void 0);
__decorate([
    Type(() => VehicleModelWithImagesDto),
    __metadata("design:type", VehicleModelWithImagesDto)
], ReservationInsuranceInfoWithPreReservationDto.prototype, "vehicleModel", void 0);
__decorate([
    Type(() => ReservationExtraItemDto),
    __metadata("design:type", Array)
], ReservationInsuranceInfoWithPreReservationDto.prototype, "extraItems", void 0);
__decorate([
    Type(() => ReservationInsuranceDto),
    __metadata("design:type", Array)
], ReservationInsuranceInfoWithPreReservationDto.prototype, "insurances", void 0);
