export class InsuranceForD2CDto {
    constructor(initializer) {
        this.insuranceName = initializer.insuranceName;
        this.listPrice = initializer.listPrice;
        this.userFinalPrice = initializer.userFinalPrice;
        this.insurancePrice = initializer.insurancePrice;
        this.finalPrice = initializer.finalPrice;
        this.fullCoverageInsurancePrice = initializer.fullCoverageInsurancePrice;
    }
}
