var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsDateString, IsEnum, IsNumber, IsOptional, } from 'class-validator';
import { InsuranceName } from '../../../enums';
export class CreatePreReservationOnlyDto {
    constructor(data) {
        var _a;
        this.id = data.id;
        this.companyId = data.companyId;
        this.vehicleModelId = data.vehicleModelId;
        this.userId = (_a = data.userId) !== null && _a !== void 0 ? _a : null;
        this.insuranceName = data.insuranceName;
        this.price = data.price;
        this.listPrice = data.listPrice;
        this.insurancePrice = data.insurancePrice;
        this.fullCoverageInsurancePrice = data.fullCoverageInsurancePrice;
        this.pickUpAt = data.pickUpAt;
        this.dropOffAt = data.dropOffAt;
    }
}
__decorate([
    IsNumber(),
    __metadata("design:type", String)
], CreatePreReservationOnlyDto.prototype, "id", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreatePreReservationOnlyDto.prototype, "companyId", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    __metadata("design:type", Object)
], CreatePreReservationOnlyDto.prototype, "userId", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreatePreReservationOnlyDto.prototype, "vehicleModelId", void 0);
__decorate([
    IsEnum(InsuranceName),
    __metadata("design:type", String)
], CreatePreReservationOnlyDto.prototype, "insuranceName", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreatePreReservationOnlyDto.prototype, "price", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreatePreReservationOnlyDto.prototype, "listPrice", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreatePreReservationOnlyDto.prototype, "insurancePrice", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], CreatePreReservationOnlyDto.prototype, "fullCoverageInsurancePrice", void 0);
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], CreatePreReservationOnlyDto.prototype, "pickUpAt", void 0);
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], CreatePreReservationOnlyDto.prototype, "dropOffAt", void 0);
