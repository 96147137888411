import { AccidentState, BankCardType, CalculationMethod, D2CDiscountCategory, DriverLicenseType, DrivingSystem, ExtraItemCategory, ExtraItemFeeCriterion, Fuel, FuelRateUnit, InsuranceCalculationMethod, ManagerApprovalType, ManagerHistorySearchType, Manufacturer, RequestSource, ReservationPaymentType, ReservationRoute, ReservationState, ReservationTaskSubtype, SearchType, SettlementState, TaskSubtype, TaskType, Transmission, UserRole, VehicleModelFeeCalculationMethod, VehicleModelState, VehicleModelType, VendorType, } from '../enums';
export const translateVendorType = (type) => {
    let translatedDivision;
    switch (type) {
        case VendorType.ACCOMMODATION:
            translatedDivision = '숙소';
            break;
        case VendorType.ETC:
            translatedDivision = '기타';
            break;
        case VendorType.INSURER:
            translatedDivision = '보험사';
            break;
        case VendorType.AGENCY:
            translatedDivision = '여행사';
            break;
        case VendorType.COUPON:
            translatedDivision = '할인쿠폰';
            break;
    }
    return translatedDivision;
};
export const translateVendorCalculationMethod = (calculationMethod) => {
    let translatedCalculationMethod;
    switch (calculationMethod) {
        case 'DEFAULT':
            translatedCalculationMethod = '기본값';
            break;
        case CalculationMethod.BASIC:
            translatedCalculationMethod = '출발일기준';
            break;
        case CalculationMethod.STANDARD:
            translatedCalculationMethod = '24시간기준';
            break;
        case CalculationMethod.MIDNIGHT:
            translatedCalculationMethod = '자정기준';
            break;
    }
    return translatedCalculationMethod;
};
export const translateUserRole = (role) => {
    let translatedRole;
    switch (role) {
        case UserRole.ADMIN:
            translatedRole = '관리자';
            break;
        case UserRole.MIDDLE:
            translatedRole = '중간관리';
            break;
        case UserRole.NORMAL:
            translatedRole = '직원';
            break;
        case UserRole.VENDOR:
            translatedRole = '거래처';
            break;
        case UserRole.KIOSK:
            translatedRole = '키오스크';
            break;
    }
    return translatedRole;
};
export const translateUserAuthority = (authority) => {
    let translatedAuthority;
    switch (authority) {
        case 'vendor':
            translatedAuthority = '거래처관리';
            break;
        case 'product':
            translatedAuthority = '상품관리';
            break;
        case 'history':
            translatedAuthority = '변경기록';
            break;
        case 'accounting':
            translatedAuthority = '정산';
            break;
        case 'maintenance':
            translatedAuthority = '정비';
            break;
        case 'board':
            translatedAuthority = '게시판';
            break;
        case 'preallocation':
            translatedAuthority = '사전할당블럭';
            break;
    }
    return translatedAuthority;
};
export const translateBankCardType = (type) => {
    let translatedType;
    switch (type) {
        case BankCardType.BANK:
            translatedType = '은행';
            break;
        case BankCardType.CARD:
            translatedType = '카드';
            break;
        case BankCardType.KIOSK:
            translatedType = '키오스크';
            break;
        case BankCardType.ETC:
            translatedType = '기타';
            break;
    }
    return translatedType;
};
export const translateVehicleModelState = (state) => {
    let translatedState;
    switch (state) {
        case VehicleModelState.USABLE:
            translatedState = '사용가능';
            break;
        case VehicleModelState.UNUSABLE:
            translatedState = '사용불가';
            break;
    }
    return translatedState;
};
export const translateVehicleModelType = (type) => {
    let translatedState;
    switch (type) {
        case VehicleModelType.URBAN:
            translatedState = '경차';
            break;
        case VehicleModelType.CITY:
            translatedState = '소형';
            break;
        case VehicleModelType.COMPACT:
            translatedState = '준중형';
            break;
        case VehicleModelType.MIDSIZE:
            translatedState = '중형';
            break;
        case VehicleModelType.RV:
            translatedState = '승합(RV)';
            break;
        case VehicleModelType.SUV:
            translatedState = 'SUV';
            break;
        case VehicleModelType.LUXURY:
            translatedState = '고급';
            break;
        case VehicleModelType.IMPORTED:
            translatedState = '수입';
            break;
        case VehicleModelType.EV:
            translatedState = '전기차';
            break;
        case VehicleModelType.SUPERCAR:
            translatedState = '슈퍼카';
            break;
    }
    return translatedState;
};
export const translateVehicleModelFuel = (fuel) => {
    let translatedFuel;
    switch (fuel) {
        case Fuel.DIESEL:
            translatedFuel = '경유';
            break;
        case Fuel.EV:
            translatedFuel = '전기';
            break;
        case Fuel.GASOLINE:
            translatedFuel = '휘발유';
            break;
        case Fuel.HYBRID:
            translatedFuel = '하이브리드';
            break;
        case Fuel.LPG:
            translatedFuel = 'LPG';
            break;
    }
    return translatedFuel;
};
export const translateVehicleModelManufacturer = (value) => {
    let translatedValue;
    switch (value) {
        case Manufacturer.HYUNDAI:
            translatedValue = '현대';
            break;
        case Manufacturer.KIA:
            translatedValue = '기아';
            break;
        case Manufacturer.SAMSUNG:
            translatedValue = '삼성';
            break;
        case Manufacturer.SSANGYONG:
            translatedValue = '쌍용';
            break;
        case Manufacturer.GM:
            translatedValue = 'GM';
            break;
        case Manufacturer.IMPORTED:
            translatedValue = '수입';
            break;
        case Manufacturer.MERCEDES:
            translatedValue = '벤츠';
            break;
        case Manufacturer.BMW:
            translatedValue = 'BMW';
            break;
        case Manufacturer.AUDI:
            translatedValue = '아우디';
            break;
        case Manufacturer.TESLA:
            translatedValue = '테슬라';
            break;
        case Manufacturer.VOLKSWAGEN:
            translatedValue = '폭스바겐';
            break;
        case Manufacturer.VOLVO:
            translatedValue = '볼보';
            break;
    }
    return translatedValue;
};
export const translateVehicleModelTransmission = (value) => {
    let translatedValue;
    switch (value) {
        case Transmission.AUTOMATIC:
            translatedValue = '자동';
            break;
        case Transmission.MANUAL:
            translatedValue = '수동';
            break;
    }
    return translatedValue;
};
export const translateVehicleModelDrivingSystem = (value) => {
    let translatedValue;
    switch (value) {
        case DrivingSystem.FRONT:
            translatedValue = '전륜';
            break;
        case DrivingSystem.REAR:
            translatedValue = '후륜';
            break;
        case DrivingSystem.FOUR:
            translatedValue = '4륜';
            break;
    }
    return translatedValue;
};
export const translateVehicleModelKeyOption = (option) => {
    let translatedOption;
    switch (option) {
        case 'remoteKey':
            translatedOption = '리모콘';
            break;
        case 'smartKey':
            translatedOption = '스마트키';
            break;
        case 'immobilizer':
            translatedOption = '이모빌라이저';
            break;
        case 'generalKey':
            translatedOption = '일반키';
            break;
        case 'carKey':
            translatedOption = '카키';
            break;
        case 'mobileKey':
            translatedOption = '모바일키';
            break;
    }
    return translatedOption;
};
export const translateVehicleModelOption = (option) => {
    let translatedOption;
    switch (option) {
        case 'leatherSeat':
            translatedOption = '가죽시트';
            break;
        case 'noSmoke':
            translatedOption = '금연';
            break;
        case 'navigation1':
            translatedOption = '네비(거치형)';
            break;
        case 'navigation2':
            translatedOption = '네비(매립형)';
            break;
        case 'navigation3':
            translatedOption = '네비게이션';
            break;
        case 'blackBox':
            translatedOption = '블랙박스';
            break;
        case 'spareTire':
            translatedOption = '스페어 타이어';
            break;
        case 'seatHeater':
            translatedOption = '시트열선';
            break;
        case 'seatHeater2':
            translatedOption = '시트열선(후)';
            break;
        case 'sunroof':
            translatedOption = '썬루프';
            break;
        case 'driverSideAirbag':
            translatedOption = '운전석에어백';
            break;
        case 'powerSeat':
            translatedOption = '전동시트';
            break;
        case 'electricTrunk':
            translatedOption = '전동트렁크';
            break;
        case 'passengerSideAirbag':
            translatedOption = '조수석에어백';
            break;
        case 'pneumaticPump':
            translatedOption = '타이어 공기 주입기';
            break;
        case 'temporaryTire':
            translatedOption = '템프로리 타이어';
            break;
        case 'ventilatedSeat1':
            translatedOption = '통풍시트운전석';
            break;
        case 'ventilatedSeat2':
            translatedOption = '통풍시트조수석';
            break;
        case 'heatedSteeringWheel':
            translatedOption = '핸들열선';
            break;
        case 'parkingSensor':
            translatedOption = '후방센서';
            break;
        case 'parkingCamera':
            translatedOption = '후방카메라';
            break;
        case 'ABS':
            translatedOption = 'ABS';
            break;
        case 'ISG':
            translatedOption = '오토 스탑';
            break;
        case 'EPB':
            translatedOption = '오토 홀드';
            break;
        case 'FCA':
            translatedOption = '전방 추돌 방지 시스템';
            break;
        case 'RCCA':
            translatedOption = '후방 추돌 방지 시스템';
            break;
        case 'LKA':
            translatedOption = '차선 이탈 방지 시스템';
            break;
        case 'wirelessCharger':
            translatedOption = '무선 충전기';
            break;
        case 'aroundView':
            translatedOption = '어라운드뷰';
            break;
        case 'androidAuto':
            translatedOption = '안드로이드 오토';
            break;
        case 'appleCarPlay':
            translatedOption = '애플 카플레이';
            break;
    }
    return translatedOption;
};
export const translateVehicleModelAudio = (audio) => {
    let translatedAudio;
    switch (audio) {
        case 'bluetooth':
            translatedAudio = '블루투스';
            break;
        case 'aux':
            translatedAudio = 'AUX';
            break;
        case 'cd':
            translatedAudio = 'CD';
            break;
        case 'iPod':
            translatedAudio = 'iPod';
            break;
        case 'mp3':
            translatedAudio = 'MP3';
            break;
        case 'usbA':
            translatedAudio = 'USB A';
            break;
        case 'usbC':
            translatedAudio = 'USB C';
            break;
    }
    return translatedAudio;
};
export const translateFeeCalculationMethod = (calculation) => {
    let translatedCalculation;
    switch (calculation) {
        case VehicleModelFeeCalculationMethod.HOUR_0:
            translatedCalculation = '24+0';
            break;
        case VehicleModelFeeCalculationMethod.HOUR_1:
            translatedCalculation = '24+1';
            break;
        case VehicleModelFeeCalculationMethod.HOUR_2:
            translatedCalculation = '24+2';
            break;
        case VehicleModelFeeCalculationMethod.HOUR_3:
            translatedCalculation = '24+3';
            break;
        case VehicleModelFeeCalculationMethod.HOUR_4:
            translatedCalculation = '24+4';
            break;
        case VehicleModelFeeCalculationMethod.HOUR_5:
            translatedCalculation = '24+5';
            break;
        case VehicleModelFeeCalculationMethod.SIMPLE_1:
            translatedCalculation = '24+1 단일초과요금';
            break;
        case VehicleModelFeeCalculationMethod.SIMPLE_2:
            translatedCalculation = '24+2 단일초과요금';
            break;
        case VehicleModelFeeCalculationMethod.N_HOUR_1_0:
            translatedCalculation = 'N24+1#0';
            break;
        case VehicleModelFeeCalculationMethod.N_HOUR_2_0:
            translatedCalculation = 'N24+2#0';
            break;
        case VehicleModelFeeCalculationMethod.N_HOUR_2_1:
            translatedCalculation = 'N24+2#1';
            break;
        case VehicleModelFeeCalculationMethod.N_HOUR_3_0:
            translatedCalculation = 'N24+3#0';
            break;
        case VehicleModelFeeCalculationMethod.N_HOUR_3_1:
            translatedCalculation = 'N24+3#1';
            break;
        case VehicleModelFeeCalculationMethod.N_HOUR_4_0:
            translatedCalculation = 'N24+4#0';
            break;
        case VehicleModelFeeCalculationMethod.N_HOUR_4_1:
            translatedCalculation = 'N24+4#1';
            break;
    }
    return translatedCalculation;
};
export const translateDayOfWeek = (day) => {
    let translatedDay;
    switch (day) {
        case 'monday':
            translatedDay = '월';
            break;
        case 'tuesday':
            translatedDay = '화';
            break;
        case 'wednesday':
            translatedDay = '수';
            break;
        case 'thursday':
            translatedDay = '목';
            break;
        case 'friday':
            translatedDay = '금';
            break;
        case 'saturday':
            translatedDay = '토';
            break;
        case 'sunday':
            translatedDay = '일';
            break;
    }
    return translatedDay;
};
// Vehicle
export const translateVehicleState = (state) => {
    let translatedState;
    switch (state) {
        case VehicleModelState.USABLE:
            translatedState = '사용';
            break;
        case VehicleModelState.UNUSABLE:
            translatedState = '사용안함';
            break;
    }
    return translatedState;
};
// VehicleAccident
export const translateVehicleAccidentState = (state) => {
    let translatedState;
    switch (state) {
        case AccidentState.HANDLING:
            translatedState = '처리중';
            break;
        case AccidentState.COMPLETED:
            translatedState = '처리완료';
            break;
    }
    return translatedState;
};
// Insurance
export const translateInsuranceCalculation = (calculation) => {
    let translatedCalculation;
    switch (calculation) {
        case InsuranceCalculationMethod.DAILY_7:
            translatedCalculation = '일단위(7일)';
            break;
        case InsuranceCalculationMethod.DAILY_357:
            translatedCalculation = '일단위(357일)';
            break;
        case InsuranceCalculationMethod.DAILY_EXCESS:
            translatedCalculation = '일단위(초과)';
            break;
        case InsuranceCalculationMethod.HOUR_0:
            translatedCalculation = '24+0';
            break;
        case InsuranceCalculationMethod.HOUR_1:
            translatedCalculation = '24+1';
            break;
        case InsuranceCalculationMethod.HOUR_2:
            translatedCalculation = '24+2';
            break;
        case InsuranceCalculationMethod.HOUR_3:
            translatedCalculation = '24+3';
            break;
        case InsuranceCalculationMethod.HOUR_4:
            translatedCalculation = '24+4';
            break;
        case InsuranceCalculationMethod.HOUR_5:
            translatedCalculation = '24+5';
            break;
        case InsuranceCalculationMethod.HOUR_24:
            translatedCalculation = '24×n';
            break;
        case InsuranceCalculationMethod.N_HOUR_1:
            translatedCalculation = 'N24+1#0';
            break;
        case InsuranceCalculationMethod.N_HOUR_2:
            translatedCalculation = 'N24+2#0';
            break;
        case InsuranceCalculationMethod.N_HOUR_3:
            translatedCalculation = 'N24+3#0';
            break;
    }
    return translatedCalculation;
};
//WebContent
export const translateVisibilityMap = (hide) => {
    let translatedHide;
    switch (hide) {
        case 'vendor':
            translatedHide = '거래처프로그램';
            break;
        case 'openApi':
            translatedHide = '실시간API';
            break;
        case 'onlineContract':
            translatedHide = '전자계약서';
            break;
        case 'kiosk':
            translatedHide = '키오스크';
            break;
        case 'onlineCheckIn':
            translatedHide = '사전등록';
            break;
    }
    return translatedHide;
};
//ExtraItem
export const translateExtraItemCategory = (category) => {
    let translatedCategory;
    switch (category) {
        case ExtraItemCategory.ETC:
            translatedCategory = '기타';
            break;
        case ExtraItemCategory.DISPATCH_FEE:
            translatedCategory = '배차비';
            break;
        case ExtraItemCategory.REPAIR_FEE:
            translatedCategory = '수리비';
            break;
        case ExtraItemCategory.FUEL_FEE:
            translatedCategory = '연료비';
            break;
        case ExtraItemCategory.PARKING_FEE:
            translatedCategory = '주차비';
            break;
        case ExtraItemCategory.SPECIAL_CONTRACT:
            translatedCategory = '자차특약';
            break;
        case ExtraItemCategory.CHAIN:
            translatedCategory = '체인';
            break;
        case ExtraItemCategory.CHARGE_CARD:
            translatedCategory = '충전카드';
            break;
        case ExtraItemCategory.ROUND_FEE:
            translatedCategory = '회차비';
            break;
    }
    return translatedCategory;
};
export const translateExtraItemFeeCriterion = (type) => {
    let translatedType;
    switch (type) {
        case ExtraItemFeeCriterion.RESERVATION:
            translatedType = '예약별';
            break;
        case ExtraItemFeeCriterion.ONE:
            translatedType = '1일별';
            break;
        case ExtraItemFeeCriterion.TWO:
            translatedType = '2일별';
            break;
        case ExtraItemFeeCriterion.THREE:
            translatedType = '3일별';
            break;
        case ExtraItemFeeCriterion.FOUR:
            translatedType = '4일별';
            break;
        case ExtraItemFeeCriterion.FIVE:
            translatedType = '5일별';
            break;
        case ExtraItemFeeCriterion.SIX:
            translatedType = '6일별';
            break;
        case ExtraItemFeeCriterion.SEVEN:
            translatedType = '7일별';
            break;
    }
    return translatedType;
};
// Reservation
export const translateReservationState = (state) => {
    let translatedState;
    switch (state) {
        case ReservationState.IN_USE:
            translatedState = '대여중';
            break;
        case ReservationState.PENDING:
            translatedState = '예약대기';
            break;
        case ReservationState.IN_PROGRESS:
            translatedState = '예약중';
            break;
        case ReservationState.CONFIRMED:
            translatedState = '예약확정';
            break;
        case ReservationState.CANCELED:
            translatedState = '예약취소';
            break;
        case ReservationState.CLOSED:
            translatedState = '예약마감';
            break;
        case ReservationState.RETURNED:
            translatedState = '반납완료';
            break;
    }
    return translatedState;
};
export const translateReservationRoute = (route) => {
    let translatedRoute;
    switch (route) {
        case ReservationRoute.VENDOR_INTERNET:
            translatedRoute = '거래처인터넷';
            break;
        case ReservationRoute.VENDOR_PHONE:
            translatedRoute = '거래처전화';
            break;
        case ReservationRoute.CUSTOMER_INTERNET:
            translatedRoute = '고객인터넷';
            break;
        case ReservationRoute.CUSTOMER_PHONE:
            translatedRoute = '고객전화';
            break;
        case ReservationRoute.REALTIME:
            translatedRoute = '실시간API';
            break;
    }
    return translatedRoute;
};
export const translateFuelRateUnit = (unit) => {
    let translatedUnit;
    switch (unit) {
        case FuelRateUnit.PERCENTAGE:
            translatedUnit = '%';
            break;
        case FuelRateUnit.SLOT:
            translatedUnit = '칸';
            break;
    }
    return translatedUnit;
};
export const translateDriverLicenseType = (type) => {
    let translatedType;
    switch (type) {
        case DriverLicenseType.FIRST_LARGE:
            translatedType = '1종대형';
            break;
        case DriverLicenseType.FIRST_ORDINARY:
            translatedType = '1종보통';
            break;
        case DriverLicenseType.FIRST_SMALL:
            translatedType = '1종소형';
            break;
        case DriverLicenseType.FIRST_LARGE_TRAILER:
            translatedType = '특수(대형견인)';
            break;
        case DriverLicenseType.FIRST_WRECKER:
            translatedType = '특수(구난)';
            break;
        case DriverLicenseType.FIRST_SMALL_TRAILER:
            translatedType = '특수(소형견인)';
            break;
        case DriverLicenseType.SECOND_ORDINARY:
            translatedType = '2종보통';
            break;
        case DriverLicenseType.SECOND_SMALL:
            translatedType = '2종소형';
            break;
        case DriverLicenseType.SECOND_MOTORIZED_BICYCLE:
            translatedType = '2종원동기';
            break;
    }
    return translatedType;
};
export const translatePaymentType = (type) => {
    let translatedType;
    switch (type) {
        case ReservationPaymentType.PAYMENT_CUSTOMER:
            translatedType = '고객납입';
            break;
        case ReservationPaymentType.PAYMENT_VENDOR:
            translatedType = '거래처납입';
            break;
        case ReservationPaymentType.REFUND:
            translatedType = '고객환불';
            break;
    }
    return translatedType;
};
export const translateSettlementState = (state) => {
    let translatedState;
    switch (state) {
        case SettlementState.PENDING_DEPOSIT:
            translatedState = '입금대기';
            break;
        case SettlementState.PENDING_WITHDRAWAL:
            translatedState = '출금대기';
            break;
        case SettlementState.CONFIRMED:
            translatedState = '완료';
            break;
        case SettlementState.CANCELED:
            translatedState = '취소';
            break;
    }
    return translatedState;
};
export const translateTaskType = (type) => {
    let taskType;
    switch (type) {
        case TaskType.USER:
            taskType = '사용자';
            break;
        case TaskType.USER_PROFILE:
            taskType = '사용자(개인)';
            break;
        case TaskType.COMPANY_PROFILE:
            taskType = '회사 정보';
            break;
        case TaskType.VENDOR:
            taskType = '거래처';
            break;
        case TaskType.VEHICLE:
            taskType = '등록차량';
            break;
        case TaskType.VEHICLE_MODEL:
            taskType = '차량모델';
            break;
        case TaskType.INSURANCE:
            taskType = '자차보험';
            break;
        case TaskType.FEE:
            taskType = '요금';
            break;
    }
    return taskType;
};
export const translateTaskSubtype = (type) => {
    let taskSubtype;
    switch (type) {
        case TaskSubtype.CREATE:
            taskSubtype = '생성';
            break;
        case TaskSubtype.UPDATE:
            taskSubtype = '수정';
            break;
        case TaskSubtype.DELETE:
            taskSubtype = '삭제';
            break;
        case TaskSubtype.COPY:
            taskSubtype = '복사';
            break;
    }
    return taskSubtype;
};
export const translateReservationTaskSubtype = (type) => {
    let reservationTaskSubtype;
    switch (type) {
        case ReservationTaskSubtype.CREATE:
            reservationTaskSubtype = '생성';
            break;
        case ReservationTaskSubtype.UPDATE:
            reservationTaskSubtype = '수정';
            break;
        case ReservationTaskSubtype.PICK_UP:
            reservationTaskSubtype = '대여';
            break;
        case ReservationTaskSubtype.DROP_OFF:
            reservationTaskSubtype = '반납';
            break;
        case ReservationTaskSubtype.CANCEL:
            reservationTaskSubtype = '취소';
            break;
        case ReservationTaskSubtype.SWAP:
            reservationTaskSubtype = '차량번호스왑';
            break;
        case ReservationTaskSubtype.ALIGN:
            reservationTaskSubtype = '예약정렬';
            break;
    }
    return reservationTaskSubtype;
};
export const translateSearchType = (type) => {
    let searchType;
    switch (type) {
        case SearchType.PRODUCT_NAME:
            searchType = '상품명';
            break;
        case SearchType.INCLUDE_PRODUCT_NAME:
            searchType = '상품명(포함)';
            break;
        case SearchType.PRODUCT_ID:
            searchType = '상품ID';
            break;
        case SearchType.USER_USERNAME:
            searchType = '작업자ID';
            break;
    }
    return searchType;
};
export const translateVehicleProfileAuthority = (audio) => {
    let translatedAuthority;
    switch (audio) {
        case 'loanRestrict':
            translatedAuthority = '대여가능수제한';
            break;
        case 'reservationProhibit':
            translatedAuthority = '새예약금지';
            break;
        case 'reservationStatus':
            translatedAuthority = '예약현황';
            break;
        case 'vehicleInfo':
            translatedAuthority = '차량정보';
            break;
    }
    return translatedAuthority;
};
export const translateManagerHistorySearchType = (type) => {
    let searchType;
    switch (type) {
        case ManagerHistorySearchType.DISPATCH_AREA:
            searchType = '배차구역';
            break;
        case ManagerHistorySearchType.RESERVED_VEHICLE:
            searchType = '차량번호지정';
            break;
        case ManagerHistorySearchType.DRIVER_LICENSE_IMAGE:
            searchType = '면허증';
            break;
    }
    return searchType;
};
export const translateManagerApprovalType = (type) => {
    switch (type) {
        case ManagerApprovalType.SKIP_DRIVER_LICENSE_IMAGE:
            return '[면허] 증빙 서류 확인';
        case ManagerApprovalType.SKIP_DRIVER_LICENSE_VALIDATION:
            return '[면허] 운전 만 경력 확인';
        case ManagerApprovalType.SKIP_PAYMENT:
            return '[결제] 납입 대체';
        case ManagerApprovalType.EDIT_DRIVER_LICENSE_INFO:
            return '[면허] 정보 수정';
        case ManagerApprovalType.BLACK_LIST:
            return '[블랙리스트] 대여 제한';
    }
};
export const translateManagerApprovalTypeIsApproved = (type, isApproved) => {
    switch (type) {
        case ManagerApprovalType.SKIP_DRIVER_LICENSE_IMAGE:
            return isApproved
                ? '승인 완료 : [면허] 증빙 서류 확인'
                : '요청 : [면허] 증빙 서류 확인';
        case ManagerApprovalType.SKIP_DRIVER_LICENSE_VALIDATION:
            return isApproved
                ? '승인 완료 : [면허] 운전 만 경력 확인'
                : '요청 : [면허] 운전 만 경력 확인';
        case ManagerApprovalType.SKIP_PAYMENT:
            return isApproved
                ? '승인 완료 : [결제] 납입 대체'
                : '요청 : [결제] 납입 대체';
        case ManagerApprovalType.EDIT_DRIVER_LICENSE_INFO:
            return isApproved
                ? '승인 완료 : [면허] 정보 수정 확인'
                : '요청 : [면허] 정보 수정 확인';
        case ManagerApprovalType.BLACK_LIST:
            return isApproved
                ? '승인 완료 : [블랙리스트] 대여 제한'
                : '요청 : [블랙리스트] 대여 제한';
    }
};
export const translateRequestSource = (source) => {
    let requestSource;
    switch (source) {
        case RequestSource.ERP:
            requestSource = '예약관리프로그램';
            break;
        case RequestSource.VENDOR:
            requestSource = '거래처계정';
            break;
        case RequestSource.KIOSK:
            requestSource = '키오스크';
            break;
        case RequestSource.ONLINE_CONTRACT:
            requestSource = '전자계약서';
            break;
        case RequestSource.OPEN_API:
            requestSource = '오픈 API';
            break;
        case RequestSource.ONLINE_CHECK_IN:
            requestSource = '사전 등록';
            break;
    }
    return requestSource;
};
export const translateD2CDiscountCategory = (category) => {
    let translatedCategory;
    switch (category) {
        case D2CDiscountCategory.DISCOUNT_CODE:
            translatedCategory = '할인 코드';
            break;
        case D2CDiscountCategory.USER_DISCOUNT:
            translatedCategory = '회원가';
            break;
    }
    return translatedCategory;
};
