var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsEnum, IsNumber, IsOptional } from 'class-validator';
import { DiscountType } from '../../../enums';
export class UpdateD2CUserDiscountPolicyDto {
}
__decorate([
    IsEnum(DiscountType),
    IsOptional(),
    __metadata("design:type", String)
], UpdateD2CUserDiscountPolicyDto.prototype, "discountType", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], UpdateD2CUserDiscountPolicyDto.prototype, "discountValue", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateD2CUserDiscountPolicyDto.prototype, "maxDiscountAmount", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Object)
], UpdateD2CUserDiscountPolicyDto.prototype, "minOrderAmount", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], UpdateD2CUserDiscountPolicyDto.prototype, "excludeSpecialContractFromDiscount", void 0);
__decorate([
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], UpdateD2CUserDiscountPolicyDto.prototype, "isActive", void 0);
