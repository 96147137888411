import dayjs from 'dayjs';
export const getAge = (date, baseDate) => {
    return dayjs(baseDate).endOf('D').diff(date, 'year');
};
/**
 * @returns 3, if 2박 3일
 */
export const getRentalDays = (start, end) => {
    return dayjs(end).endOf('day').diff(dayjs(start), 'day') + 1;
};
export const getHoursBetween = (pickUpAt, dropOffAt) => {
    return Math.ceil(dayjs(dropOffAt).diff(dayjs(pickUpAt), 'hour', true));
};
export const getHoursRemainInDay = (from) => {
    return Math.ceil(dayjs(from).add(1, 'day').startOf('day').diff(from, 'hour', true));
};
export const getFollowingDays = (from, length) => {
    return Array.from({ length }).map((_, index) => {
        return dayjs(from)
            .add(index + 1, 'day')
            .toDate();
    });
};
export const isWeekend = (vehicleModelFeeWeekend, date) => {
    const WEEK_OF_DAY = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
    ];
    return vehicleModelFeeWeekend[WEEK_OF_DAY[dayjs(date).day()]];
};
export const calculateDDay = (target) => {
    const today = dayjs().startOf('day');
    const targetDate = dayjs(target).startOf('day');
    return targetDate.diff(today, 'day');
};
export const getMonthAgo = (date, month) => {
    return dayjs(date).subtract(month, 'month').toDate();
};
