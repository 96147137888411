import {
  formatPhoneNumber,
  type ManagerApprovalType,
  translateManagerApprovalType,
} from '@orcar/common';
import dayjs from 'dayjs';
import { useCallback, useRef } from 'react';
import { type PaymentData } from '@/pages/payment/PaymentPage';
import { type VehicleReadyData } from '@/pages/vehicleReady/VehicleReadyPage';
import {
  Br,
  Cut,
  Line,
  Printer,
  render,
  Row,
  Text,
} from '@/printer/react-thermal-printer';
import { useGetProfile } from './company-profile.hook';

export const usePrintReceipt = () => {
  const portRef = useRef<SerialPort | null>(null);

  const { data: companyProfile } = useGetProfile();

  const getReceiptData = useCallback(
    (
      vehicleReadyData: VehicleReadyData,
      paymentData: PaymentData | null,
      managerApprovals: ManagerApprovalType[],
    ) => {
      const companyRegistrationNumber = companyProfile?.registrationNumber
        ? companyProfile.registrationNumber
            .replace(/\D/g, '')
            .slice(0, 10)
            .replace(/(\d{0,3})(\d{0,2})(\d{0,5})/, '$1-$2-$3')
            .replace(/-+$/, '')
        : '';

      const companyPhoneNumber = companyProfile?.phoneNumber1
        ? formatPhoneNumber(companyProfile.phoneNumber1)
        : '';

      return [
        '-',
        '출고 대기 확인증',
        '-',
        ...(vehicleReadyData.driverName
          ? [
              ['제1 운전자', vehicleReadyData.contractorName],
              ['제2 운전자', vehicleReadyData.driverName],
            ]
          : [['운전자', vehicleReadyData.contractorName]]),
        [
          '대여 일시',
          dayjs(vehicleReadyData.pickUpAt).format('YYYY-MM-DD HH:mm'),
        ],
        [
          '반납 일시',
          dayjs(vehicleReadyData.actualDropOffAt).format('YYYY-MM-DD HH:mm'),
        ],
        ['차량 모델', vehicleReadyData.vehicleModelName],
        '-',
        ['배차 구역', vehicleReadyData.dispatchArea],
        ['차량 번호', vehicleReadyData.vehicleNumber],
        ...(managerApprovals.length
          ? [
              '-',
              '관리자 확인 내역',
              ...managerApprovals.map(
                (type) => `- ${translateManagerApprovalType(type)}`,
              ),
            ]
          : []),
        '', // Cut
        ...(paymentData
          ? [
              '-',
              '영수증',
              '-',
              ['결제방법', paymentData.paymentMethod],
              ['카드번호', paymentData.cardNumber],
              ['승인일시', paymentData.paidAt],
              ['할부기간', paymentData.installment],
              ['공급가액', paymentData.supply],
              ['부가세액', paymentData.vat],
              '-',
              ['결제금액', paymentData.amount],
              '\n', // Br
              '-',
              ['사업자명', companyProfile?.name ?? ''],
              ['사업자등록번호', companyRegistrationNumber],
              ['대표자명', companyProfile?.representativeName ?? ''],
              ['전화번호', companyPhoneNumber],
              ['주소', companyProfile?.address ?? ''],
              '', // Cut
            ]
          : []),
      ];
    },
    [companyProfile],
  );

  const getReceiptElement = useCallback((data: (string | string[])[]) => {
    return (
      <Printer type='epson' width={48} characterSet='korea'>
        {data.map((line, index) => {
          if (Array.isArray(line)) {
            if (['배차 구역', '차량 번호'].includes(line[0])) {
              return (
                <Row
                  key={index}
                  left={<Text size={{ width: 2, height: 2 }}>{line[0]}</Text>}
                  right={<Text size={{ width: 2, height: 2 }}>{line[1]}</Text>}
                />
              );
            } else {
              return <Row left={line[0]} right={line[1]} />;
            }
          } else if (line === '-') {
            return <Line key={index} />;
          } else if (line === '\n') {
            return <Br key={index} />;
          } else if (line === '') {
            return <Cut key={index} lineFeeds={2} />;
          } else {
            return (
              <Text key={index} align='center'>
                {line}
              </Text>
            );
          }
        })}
      </Printer>
    );
  }, []);

  const requestPrinterPort = useCallback(async () => {
    try {
      const port =
        (await window.navigator.serial.getPorts())[0] ??
        (await window.navigator.serial.requestPort());

      if (port) {
        portRef.current = port;
      }
    } catch (error) {
      console.error(error instanceof Error ? error.stack : error);
    }
  }, []);

  const printReceipt = useCallback(
    async (
      vehicleReadyData: VehicleReadyData,
      paymentData: PaymentData | null,
      managerApprovals: ManagerApprovalType[] = [],
    ) => {
      await requestPrinterPort();

      const data = getReceiptData(
        vehicleReadyData,
        paymentData,
        managerApprovals,
      );

      if (portRef.current) {
        if (!portRef.current.writable) {
          await portRef.current.open({ baudRate: 9600 });
        }

        const writer = portRef.current.writable?.getWriter();

        if (writer) {
          const rendered = await render(getReceiptElement(data));
          await writer.write(rendered);
          writer.releaseLock();
          return;
        }
      }

      console.log(
        data.reduce((acc, cur) => {
          if (Array.isArray(cur)) {
            return acc + `${cur[0]} : ${cur[1]}\n`;
          } else if (cur === '-') {
            return acc + '-------------------\n';
          } else if (cur === '\n') {
            return acc + '\n';
          } else if (cur === '') {
            return acc + '\n\n\n';
          } else {
            return acc + `${cur}\n`;
          }
        }, ''),
      );
    },
    [requestPrinterPort, getReceiptElement, getReceiptData],
  );

  return {
    requestPrinterPort,
    printReceipt,
  };
};
